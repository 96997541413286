<template>
    <div class="bkColor">
        <headTitle :title="title"></headTitle>
        <div class="logo">
            <img class="logoImg" src="../assets/icon/nd_icon.png" alt="">
            <div class="logoTitle">{{ year }}年度</div>
        </div>
        <div class="dataList">
            <van-cell title="项目总数" :is-link="true" :value="totalData.totalProject" @click="goPjList()" />
            <van-cell title="项目金额（元）" :is-link="false" :value="getTenThousand(totalData.totalPrice)" />
            <van-cell title="已收款（元）" :is-link="false" :value="getTenThousand(totalData.totalGathering)" />
            <!-- <van-cell title="待收款" :is-link="false" :value="" /> -->
            <van-cell title="已付款（元）" :is-link="false" :value="getTenThousand(totalData.totalPayment)" />
            <van-cell title="已报销（元）" :is-link="false" :value="getTenThousand(totalData.totalReimbursement)" />
            <van-cell title="进度逾期" :is-link="true" :value="totalData.totalLate" @click="goPjList({ 'isLate': 1 })" />
            <van-cell title="成本超额" :is-link="true" :value="totalData.totalOverBudget"
                @click="goPjList({ 'isOverBudget': 1 })" />
            <van-cell title="已完成" :is-link="true" :value="totalData.totalFinish"
                @click="goPjList({ 'projectState': 10 })" />
            <van-cell title="进行中" :is-link="true" :value="totalData.totalDoing" @click="goPjList({ 'doing': 1 })" />
            <van-cell title="未开始" :is-link="true" :value="totalData.totalNotStart" @click="goPjList({ 'notStart': 1 })" />
            <van-cell title="已取消" :is-link="true" :value="totalData.totalTerminal"
                @click="goPjList({ 'projectState': 11 })" />

        </div>
    </div>
</template>

<script>
import headTitle from '../components/headTitle.vue'
import Vue from 'vue';
import { Cell } from 'vant';
Vue.use(Cell)

import { tenThousand } from '../units/units'

export default {
    name: 'dataSummary',
    components: {
        headTitle
    },
    data() {
        return {
            title: '数据汇总',
            year: 0,
            totalData: {
                totalDoing: 0,//进行中
                totalFinish: 0,//总完成
                totalGathering: 0,//总收款
                totalLate: 0,//总逾期
                totalNotStart: 0,//未开始
                totalOverBudget: 0,//超出预算项目数
                totalPayment: 0,//总付款
                totalPrice: 0,//总金额
                totalProject: 0,//项目总数
                totalReimbursement: 0,//总报销
                totalTerminal: 0,//已取消数
            },

        }
    },
    mounted() {
        this.year = this.$route.query.year
        this.totalData = JSON.parse(this.$route.query.data)
    },
    methods: {
        goPjList(obj) {
            this.$router.push({
                path: '/pjList',
                query: {
                    'obj': obj
                }
            })
        },
        //金额转万元单位
        getTenThousand(m) {
            return tenThousand(m)
        },
    }
}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    height: 100%;
    background: #F6F6F6;
}

.logo {
    width: 100vw;
    height: 8vh;
    background: #fff;
    line-height: 8vh;
    position: relative;
    margin-bottom: 2vh;
}

.logoImg {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    /* right: 0; */
    bottom: 0;
    left: 3vw;
    margin: auto;
}

.logoTitle {
    position: absolute;
    top: 0;
    /* right: 0; */
    bottom: 0;
    left: 14vw;
    margin: auto;
    font-weight: 600;
}

.dataList {
    width: 100vw;
    height: 40vh;
    background: #fff;
}

/deep/ .van-cell,
.van-cell__right-icon {
    line-height: 42px;
}

/deep/ .van-cell__title {
    text-align: left;
}

/deep/ .van-cell__title,
.van-cell__value {
    // font-size: 19px;
    font-size: 17px;
    // font-weight: 600;
    color: #000;
}
</style>

